<template>
  <div class="drivers-step2-content">
    <div class="bg-white flex flex-row" v-if="authModeFields.drivingLicenseMainUrl">
      <div class="pl-24 pr-6 flex-1">
        <div class="d-flex justify-space-between">
          <label class="require ml-10 text-14 text-main font-500"
            >行驶证主副页<span class="text-danger">(主车)</span></label
          >
        </div>
        <div class="truck-card-wrap py-4">
          <tos-uploader
            readonly
            disabled
            class="truck-card"
            v-model="truckMainFileList"
            :max-count="1"
            bgType="xingshizheng-zhu"
          />
        </div>
      </div>
      <div class="pl-6 pr-24 flex-1">
        <div class="d-flex justify-space-between">
          <label class="ml-5 text-14 text-main font-500">行驶证其他页<span class="text-danger">(主车)</span></label>
        </div>
        <div class="truck-card-wrap py-4">
          <tos-uploader
            readonly
            class="truck-card"
            v-model="truckSideFileList"
            :max-count="1"
            bgType="xingshizheng-zhu"
          />
        </div>
      </div>
    </div>
    <div class="relative pt-8 text-center text-note text-12">上传照片，可快速识别车牌号</div>

    <!-- <div class="bg-white" v-if="authModeFields.drivingLicenseMainUrl">
      <div class="pl-24">
        <label class="require ml-10 text-18 text-main font-500">行驶证主副页</label>
      </div>

      <div class="truck-card-wrap bg-white px-24 py-8">
        <tos-uploader readonly disabled class="truck-card" v-model="truckMainFileList" :max-count="1"
          >上传行驶证可迅速识别车辆信息
        </tos-uploader>
      </div>
    </div> -->

    <van-form class="px-12" ref="form">
      <template v-for="(item, key) in FormObj">
        <van-field
          :key="key"
          :readonly="['select', 'datePicker'].includes(item.type)"
          v-if="!item.hidden"
          @click="['select', 'datePicker'].includes(item.type) ? (FormObj[key].visible = true) : ''"
          :required="item.rules[0].required"
          :value="userVertifiedRef[key]"
          @input="handleInput($event, key)"
          input-align="right"
          :name="key"
          :label="item.label"
          :placeholder="'请输入'"
          :rules="item.rules"
          :type="item.type"
          :autosize="item.autosize"
          rows="1"
        >
          <template #label>
            <div :class="{ titleError: userVertifiedRef.reasonKeys.includes(key) }">
              <div>
                <span v-if="item.tips !== undefined" @click="FormObj[key].tips = true">
                  <Icon name="xxts" svgClass="mr-4" />
                </span>
                {{ item.label }}
              </div>
              <div class="font-12" v-if="userVertifiedRef.reasonKeys.includes(key)">
                不正确
              </div>
            </div>
          </template>
          <template v-if="item.extra" #extra>
            <div class="ml-8">{{ item.extra }}</div>
          </template>
        </van-field>
      </template>
    </van-form>

    <!-- 时间弹层 -->
    <Popup v-model="FormObj.registerDate.visible" position="bottom">
      <DatetimePicker
        title="选择注册日期"
        type="date"
        :min-date="minDate"
        :maxDate="maxDate"
        v-model="currentRegister"
        show-toolbar
        @confirm="e => handlePopConfirm(e, 'registerDate')"
        @cancel="FormObj.registerDate.visible = false"
      />
    </Popup>
    <Popup v-model="FormObj.issueDate.visible" position="bottom">
      <DatetimePicker
        title="行驶证发证日期"
        type="date"
        :min-date="minDate"
        :maxDate="maxDate"
        v-model="currentIssue"
        show-toolbar
        @confirm="e => handlePopConfirm(e, 'issueDate')"
        @cancel="FormObj.issueDate.visible = false"
      />
    </Popup>
    <Popup v-model="FormObj.inspectionEndDate.visible" position="bottom">
      <div class="px-16 py-8">
        <van-button size="small" type="primary" class=" h-28" @click="handlePopConfirm(null, 'inspectionEndDate')"
          >清空</van-button
        >
      </div>
      <DatetimePicker
        title="检验有效期至"
        type="year-month"
        :min-date="minDate"
        :maxDate="maxDate"
        v-model="currentInspection"
        show-toolbar
        @confirm="e => handlePopConfirm(e, 'inspectionEndDate')"
        @cancel="FormObj.inspectionEndDate.visible = false"
      />
    </Popup>
    <!-- 弹层 -->

    <Popup v-model="FormObj.energyType.visible" round position="bottom">
      <CheckPorps
        title="请选择车辆能源类型"
        tagWidth="1rem"
        v-model="userVertifiedRef.energyType"
        :columns="FormObj.energyType.options"
        @confirm="e => handleEnergyConfirm(e, 'energyType')"
        @cancel="FormObj.energyType.visible = false"
      />
    </Popup>

    <!-- 按钮栏 -->
    <div>
      <div class="truck-btn-box">
        <Button size="large" @click="handleToNext" type="primary">下一步</Button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted, reactive } from '@vue/composition-api';
import TosUploader from '@/components/uploader/uploader.vue';
import { Button, Popup, Picker, DatetimePicker } from 'vant';
import { checkTrailerBytype, getStep2FormData } from '../constants';
import { useTruckVerifiedStore } from '@/store/truckVerified.js';
import CheckPorps from './checkCarProps';
import TruckTypePicker from './TruckTypePicker';
import { formatToDateTime } from '@wlhy-web-lib/shared';

import { buildFormByAuthModeFields } from '@/utils/authMode';
import dayjs from 'dayjs';

export default defineComponent({
  name: 'truck-step2',
  components: {
    Button,
    TosUploader,
    CheckPorps,
    Popup,
    Picker,
    DatetimePicker,
    TruckTypePicker
  },
  setup(_, ctx) {
    // 如果本地缓存有数据，从缓存中获取
    const maxDate = ref(new Date('2199/12/31'));
    const minDate = ref(new Date('1970/01/01'));
    const userVerifiedStore = useTruckVerifiedStore();
    const truckMainFileList = ref([]);
    const truckSideFileList = ref([]);

    // 1.3.4 根据配置隐藏掉表单

    const next = ref(true);

    const userVertifiedRef = computed(() => userVerifiedStore.verifiedInfo);

    const FormData = getStep2FormData(userVertifiedRef);
    buildFormByAuthModeFields(FormData, userVerifiedStore.authModeFields);
    const FormObj = reactive(FormData);

    const energyTypeVisible = ref(false);
    const plateColorVisible = ref(false);
    const currentRegister = ref(new Date());
    const currentIssue = ref(new Date());
    const currentInspection = ref(new Date());
    const toDateStr = (date, key) => {
      if (!date) return null;
      const format = key === 'inspectionEndDate' ? 'yyyy-MM' : 'yyyy-MM-dd';
      return formatToDateTime(date, format);
    };
    const handlePopConfirm = (e, typeName) => {
      userVerifiedStore.updateInfo({
        [typeName]: toDateStr(e, typeName)
      });
      FormObj[typeName].visible = false;
      validateForm(typeName);
      if (typeName === 'registerDate') {
        if (userVertifiedRef.value.issueDate) {
          validateForm('issueDate');
        }
      }
    };
    const handleEnergyConfirm = (e, typeName) => {
      userVerifiedStore.updateInfo({
        [typeName]: e.value,
        [`${typeName}Code`]: e.key
      });
      FormObj[typeName].visible = false;
      validateForm(`${typeName}`);
    };

    onMounted(async () => {
      // 根据图片内容回填
      if (userVerifiedStore?.verifiedInfo?.drivingLicenseMainUrl) {
        truckMainFileList.value = [
          {
            fileName: userVerifiedStore?.verifiedInfo?.drivingLicenseMainUrl,
            status: 'uploading',
            message: '加载中'
          }
        ];
      }
      if (userVerifiedStore?.verifiedInfo?.drivingLicenseSideUrl) {
        truckSideFileList.value = [
          {
            fileName: userVerifiedStore?.verifiedInfo?.drivingLicenseSideUrl,
            status: 'uploading',
            message: '加载中'
          }
        ];
      }
      if (userVerifiedStore?.verifiedInfo?.issueDate) {
        currentIssue.value = new Date(dayjs(userVerifiedStore?.verifiedInfo?.issueDate).valueOf());
      }
      if (userVerifiedStore?.verifiedInfo?.registerDate) {
        currentRegister.value = new Date(dayjs(userVerifiedStore?.verifiedInfo?.registerDate).valueOf());
      }
      if (userVerifiedStore?.verifiedInfo?.inspectionEndDate) {
        currentInspection.value = new Date(dayjs(userVerifiedStore?.verifiedInfo?.inspectionEndDate).valueOf());
      }
    });

    const handleInput = (value, key) => {
      userVertifiedRef.value[key] = value;
    };

    const validateForm = async (...params) => {
      const form = ctx.refs.form;
      let flag = true;
      if (form) {
        try {
          flag = await form.validate(...params);
          return true;
        } catch (e) {
          console.log(e);
          return false;
        }
      }
      return flag;
    };

    const handleToNext = async () => {
      const isValid = await validateForm();
      if (isValid) {
        const info = {};
        if (!userVerifiedStore.verifiedInfo.businessName) {
          info.businessName = userVerifiedStore.verifiedInfo.owner;
        }
        userVerifiedStore.updateInfo(info);
        const truckTypeCode = userVerifiedStore.verifiedInfo.truckTypeCode;
        const isRequired = truckTypeCode && checkTrailerBytype(truckTypeCode);
        const nextStep = isRequired ? 'trailer' : 2;
        console.log(nextStep);
        userVerifiedStore.setCurrentStep(nextStep);
      }
    };

    return {
      minDate,
      maxDate,
      next,
      FormObj,
      truckMainFileList,
      truckSideFileList,
      userVertifiedRef,
      energyTypeVisible,
      plateColorVisible,
      handlePopConfirm,
      handleInput,
      handleToNext,
      authModeFields: userVerifiedStore.authModeFields,
      currentIssue,
      currentRegister,
      handleEnergyConfirm,
      currentInspection
    };
  }
});
</script>
<style lang="less">
.truck-card-wrap {
  // padding: 0.08rem 0.16rem;
  .truck-card {
    width: 100%;
    height: 1rem;
    .tos-uploader-bg {
      background: url('https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/images/vehicle-license.png')
        no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>
